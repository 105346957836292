<template>
  <div class="bg" style="height: 92vh">
    <el-container>
      <el-header class="bg-white" style="line-height: 60px">
        <div class="dfc">
          <div class="df">
            <div class="back" @click="back">
              <img src="~@/assets/img/back1.png" alt="" />
            </div>
            <div class="twoMenubox">
              <div class="menu" :class="current == idx ? 'active' : ''" @click="menuHandle(i, idx)" v-for="(i, idx) in menu" :key="idx">
                <img :src="current == idx ? i.icon : i.activeicon" alt="" />
                <div>{{ i.title }}</div>
              </div>
            </div>
          </div>

          <!-- <el-button type="primary" icon="el-icon-document-checked" @click="save">保存</el-button> -->
        </div>
      </el-header>
      <el-main>
        <div class="vlist">
          <div v-show="current == 0">
            <entire ref="entire" :liveId="info.id" :liveInfo="liveInfo" @getlive="get_slive_config"></entire>
          </div>
          <div v-show="current == 1">
            <welcome ref="welcome" :liveId="info.id" :liveInfo="liveInfo" @getlive="get_slive_config"></welcome>
          </div>
          <div v-show="current == 2">
            <upvote ref="upvote" :liveId="info.id" :liveInfo="liveInfo" @getlive="get_slive_config"></upvote>
          </div>
          <div v-show="current == 3">
            <gift ref="gift" :liveId="info.id" :liveInfo="liveInfo" @getlive="get_slive_config"></gift>
          </div>
          <div v-show="current == 4">
            <times ref="times" :liveId="info.id" :liveInfo="liveInfo" @getlive="get_slive_config"></times>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import { get_slive_config } from "@/api/slive.js";
import myempty from "@/components/myempty.vue";
import entire from "./optComponents/entire.vue";
import welcome from "./optComponents/welcome.vue";
import upvote from "./optComponents/upvote.vue";
import gift from "./optComponents/gift.vue";
import times from "./optComponents/times.vue";
export default {
  components: {
    myempty,
    entire,
    welcome,
    upvote,
    gift,
    times,
  },
  props: {
    info: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        name: "",
        videoName: "",
      },
      activeName: "first",
      current: 0,
      menu: [
        {
          icon: require("@/assets/img/secondMenu/entire1.png"),
          activeicon: require("@/assets/img/secondMenu/entire.png"),
          title: "全局配置",
          pageName: "entire",
          params: {},
        },
        {
          icon: require("@/assets/img/secondMenu/welcome1.png"),
          activeicon: require("@/assets/img/secondMenu/welcome.png"),
          title: "欢迎配置",
          pageName: "welcome",
          params: {},
        },
        {
          icon: require("@/assets/img/secondMenu/upvote1.png"),
          activeicon: require("@/assets/img/secondMenu/upvote.png"),
          title: "点赞配置",
          pageName: "upvote",
          params: {},
        },
        {
          icon: require("@/assets/img/secondMenu/gift1.png"),
          activeicon: require("@/assets/img/secondMenu/gift.png"),
          title: "送礼配置",
          pageName: "gift",
          params: {},
        },
        {
          icon: require("@/assets/img/secondMenu/time1.png"),
          activeicon: require("@/assets/img/secondMenu/time.png"),
          title: "定时引导",
          pageName: "time",
          params: {},
        },
      ],
      visible: false,
      liveInfo: {},
    };
  },
  mounted() {
    this.get_slive_config();
  },
  methods: {
    save() {
      switch (this.current) {
        case 0:
          this.$refs.entire.save();
          break;
        case 1:
          this.$refs.welcome.save();
          break;
        case 2:
          this.$refs.upvote.save();
          break;
        case 3:
          this.$refs.gift.save();
          break;
        default:
          this.$refs.times.save();
          break;
      }
    },
    get_slive_config() {
      get_slive_config({
        slive_id: this.info.id,
      }).then((res) => {
        if (res.code == "200") {
          this.liveInfo = res.data;
        }
      });
    },
    back() {
      this.$emit("back");
    },
    menuHandle(e, idx) {
      this.current = e ? idx : 0;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-input__inner {
  border: 0;
  color: #939dae;
  background: #f2f3f5;
}

/deep/.el-dialog__body {
  padding: 20px;
}

.back {
  width: 45px;
  height: 45px;
  border-radius: 6px;
  background: #f2f7fe;
  margin-top: 8px;
  line-height: 45px;

  img {
    width: 24px;
    height: 24px;
  }
}
.listBox {
  background: #fff;
  text-align: left;
  width: 99%;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
}

.listBox1 {
  background: #fff;
  width: 100%;
  padding: 15px;
  margin: 0 0 20px;
  border-radius: 2px;
  display: flex;

  .info {
    margin-left: 20px;
    text-align: left;
    width: 86%;

    .tit {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 12px;
    }

    .btn {
      background: #d9edf5;
      padding: 1px 7px;
      color: #4284cf;
      border: 1px solid #4284cf;
      border-radius: 4px;
      margin-right: 6px;
    }
  }

  img {
    width: 90px;
    height: 160px;
    border-radius: 8px;
    overflow: hidden;
  }

  .tools {
    position: relative;
    z-index: 99;
    width: 27px;
    height: 27px;
    color: #ff5733;
    line-height: 27px;
    background: #fff;
    border: 1px solid #c8dfff;
    border-radius: 4px;
    top: 0px;
    left: 15px;
  }
}

.listBox1:last-child {
  margin-bottom: 0;
}

.twoMenubox {
  display: flex;
  justify-content: space-between;
  padding-top: 0;

  .menu {
    display: flex;
    align-items: center;
    background: #f2f7fe;
    color: #8b9ab4;
    padding: 10px 8px;
    margin: 8px 10px;
    border-radius: 6px;
    justify-content: space-around;
    width: 130px;
    height: 45px;

    img {
      margin-right: -8px;
      width: 16px;
      height: 18px;
    }
  }

  .active {
    color: #fff !important;
    background: #015ce6;
    margin: 8px 10px;
    border-radius: 6px;
  }

  img {
    width: 18px;
    height: 18px;
  }
}

.vlist {
  // width: 99%;
  .info {
    padding-top: 10px;
  }
  /deep/.el-tabs__header {
    margin: 0 !important;
    padding: 10px;
    background: #fff;
  }

  /deep/.el-tabs__nav-wrap::after {
    height: 0 !important;
  }
}

.item {
  margin-right: 15px;
  margin-bottom: 15px;
  background: #fff;
  border-radius: 5px;
  height: 315px;

  .desc {
    padding: 10px;
    text-align: left;
  }

  img {
    width: 170px;
    height: 255px;
    border-radius: 5px 5px 0 0;
  }
  .tools {
    position: relative;
    z-index: 99;
    width: 27px;
    height: 27px;
    color: #ff5733;
    line-height: 27px;
    background: #fff;
    border: 1px solid #c8dfff;
    border-radius: 4px;
    top: -310px;
    left: 135px;
  }
}

.item:last-child,
.item:nth-child(6n) {
  margin-right: 0;
}

.ipt {
  width: 32%;
  position: absolute;
  top: 3%;
  right: 2%;

  /deep/.el-input__inner {
    background: #f1f4f9 !important;
  }
}

.dlistBox {
  height: 59vh;
  overflow: scroll;
  padding: 10px 20px 0;
  margin-bottom: -20px;
  .tools {
    border: 0 !important;
    background: transparent;
  }
  .check {
    align-self: center;
  }
  /deep/.el-checkbox-group {
    font-size: 15px !important;
  }
  /deep/.el-checkbox__inner {
    width: 18px;
    height: 18px;
  }
  /deep/.el-checkbox__inner::after {
    height: 10px;
    left: 6px;
  }
  .item {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  }
  .item:nth-child(5n) {
    margin-right: 0;
  }
  .item:nth-child(6n) {
    margin-right: 15px;
  }
}
</style>
