<template>
  <div class="container bg">
    <div v-if="showlist">
      <div class="df jc-sb bg-white head">
        <div class="df">
          <el-tabs v-model="activeName">
            <el-tab-pane label="实驱直播方案" name="first"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="dfc">
          <div class="mr15 cp" @click="checkServe">
            <i v-show="serverStatus == 'success'" class="el-icon-success fs18" style="color: #67c23a"></i>
            <i v-show="serverStatus == 'reload'" class="el-icon-loading fs18" style="color: #909399"></i>
            <i v-show="serverStatus == 'fail'" class="el-icon-error fs18" style="color: #f56c6c"></i>
            {{ serverStatus | serverStatusTxt }}
          </div>
          <el-button type="primary" style="height: 35px" size="mini" icon="el-icon-circle-plus-outline" @click="dialogVisible = true">创建方案</el-button>
        </div>
      </div>
      <div class="f-w box pl20">
        <div class="df f-w" v-if="liveList.length > 0" v-infinite-scroll="load">
          <div v-for="item in liveList" :key="item.id" class="imgBox cp" @click="toLive(item)">
            <img :src="item.image_url" object-fit="cover" alt="" />
            <div class="desc">
              {{ item.name }}
              <div class="time">{{ item.create_time }}</div>
              <div class="live cp">
                <img src="@/assets/img/tolive.png" alt="" />
                去开播
              </div>
            </div>

            <div class="tools" @click.stop="getItem(item)">
              <el-dropdown trigger="click" placement="bottom" @command="handleClick">
                <span class="el-dropdown-link">
                  <i class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="option" icon="el-icon-setting">房管配置</el-dropdown-item>
                  <el-dropdown-item command="rename" icon="el-icon-edit-outline">重命名</el-dropdown-item>
                  <el-dropdown-item command="del" icon="el-icon-delete">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
        <div v-else>
          <myempty text="视频"></myempty>
        </div>
      </div>
    </div>
    <div v-else>
      <options @back="back" :info="activeInfo"></options>
    </div>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="28%" :destroy-on-close="true">
      <el-input v-model="ipt" placeholder="请输入方案名称"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="createLive">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { get_list_slive, add_slive, edit_slive, del_slive } from "@/api/slive.js";
import myempty from "@/components/myempty.vue";
import options from "./option.vue";
import { ipcApiRoute } from "@/api/main";
import { mapGetters, mapMutations } from "vuex";
export default {
  components: {
    myempty,
    options,
  },
  name: "liveList",
  data() {
    return {
      showlist: true,
      dialogVisible: false,
      activeName: "first",
      serverStatus: "reload",
      title: "创建方案",
      ipt: "",
      activeInfo: {},
      activeI: {},
      liveList: [],
      page: 1,
      pageNum: 1,
    };
  },
  filters: {
    serverStatusTxt(val) {
      return val == "success" ? "服务启动成功" : val == "fail" ? "服务启动失败" : "正在检测服务";
    },
  },
  mounted() {
    // 渲染视频
    this.getlive();
    this.initializeFolder();
    this.checkSysInfo();
    this.checkServe();
    this.initWatch();
  },
  methods: {
    initWatch() {
      this.$ipc.removeAllListeners(ipcApiRoute.ipcSendMsg);
      this.$ipc.on(ipcApiRoute.ipcSendMsg, (event, res) => {
        console.log("ipcApiRoute.ipcSendMsg:", res);
        if (res.type == "serverStatus") {
          setTimeout(() => {
            this.serverStatus = res.status;
          }, 3000);
          if(res.status=='fail'){
            this.$message.error(res.content);
          }
        }
      });
    },
    back() {
      this.showlist = true;
    },
    checkServe() {
      if (this.serverStatus == "success") return;
      this.$notify({
        title: "系统通知",
        position: "top-right",
        message: "正在检测实时驱动服务，请在服务启动后开播",
        type:'warning',
        offset: 150,
      });
      this.$ipc.invoke(ipcApiRoute.checkProcessExists);
    },
    // 初始化资源目录
    initializeFolder() {
      this.$ipc.invoke(ipcApiRoute.getDisc).then((r) => {
        this.$ipc.invoke(ipcApiRoute.createFolder, `${r}AiLive\\audio\\temp`);
        localStorage.setItem("basePath", `${r}AiLive`);
      });
    },
    checkSysInfo() {
      this.$ipc.invoke(ipcApiRoute.checkSysInfo).then((r) => {
        console.log(1231231, r);
        if (!r) {
          this.$message.error("检测到当前显卡不支持实时驱动直播");
        }
      });
    },
    getlive(isFir = true) {
      const loading = this.$loading({
        lock: true,
        text: "获取数据中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (isFir) {
        this.liveList = [];
        this.page = 1;
      }
      get_list_slive({
        page: this.page,
        item: 14,
      }).then((res) => {
        loading.close();
        if (res.code == "200") {
          this.liveList.push(...res.data.list);
          this.pageNum = res.data.page_num;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    load() {
      if (this.page < this.pageNum) {
        this.page++;
        this.getlive(false);
      }
    },
    getItem(i) {
      console.log("item", i);
      this.activeInfo = i;
    },
    handleClick(command) {
      this.type = command;
      switch (command) {
        case "option":
          console.log("item", this.activeInfo);

          this.showlist = false;
          break;
        case "rename":
          this.title = "修改直播方案名称";
          this.dialogVisible = true;
          this.ipt = this.activeInfo.name;
          break;
        case "del":
          this.$confirm("此操作将永久删除该方案, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then((_) => {
              del_slive({
                slive_id: this.activeInfo.id,
              }).then((res) => {
                if (res.code == "200") {
                  this.$message.success(res.msg);
                  this.getlive();
                } else {
                  this.$message.error(res.msg);
                }
              });
            })
            .catch((_) => {});
          break;
        default:
          break;
      }
    },
    toLive(i) {
      this.$router.push({ name: "realTimeCreate", params: { id: i.id } });
    },
    createLive() {
      if (this.ipt == "") {
        this.$message.error("请输入方案名称");
        return;
      }
      if (this.type == "rename") {
        edit_slive({
          slive_id: this.activeInfo.id,
          name: this.ipt,
        }).then((res) => {
          if (res.code == "200") {
            this.$message.success(res.msg);
            this.dialogVisible = false;
            this.getlive();
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        add_slive({
          name: this.ipt,
        }).then((res) => {
          if (res.code == "200") {
            this.$message.success(res.msg);
            this.dialogVisible = false;
            this.$router.push({
              name: "realTimeCreate",
              params: { id: res.data.id, title: this.ipt },
            });
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.imgBox {
  height: 438px;

  .tools {
    top: -395px;
  }
}

.live {
  border: 1px solid #3471ff;
  background: #015ce6;
  width: 90px;
  border-radius: 6px;
  color: #fff !important;
  padding: 3px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 5px;

  img {
    width: 25px;
    height: 25px;
  }
}

/deep/.el-input__inner {
  border: 0;
  color: #939dae;
  background: #f2f3f5;
}

/deep/.el-dialog__header {
  padding: 20px 20px 10px;
  text-align: left;
  font-weight: bold;
}

/deep/.el-main {
  padding: 15px !important;
}

/deep/.el-dialog {
  border-radius: 8px;
}
</style>
